import { isSignedInSelector } from '@redux/selectors';
import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Loader } from '@components/helpers';
import { hardGoTo as navigate } from '../../../redux/actions';
import { apiLocation } from '../../../../../config/env.json';

type QuizLayoutProps = {
  readonly children: ReactNode;
  readonly isSignedIn: boolean;
  readonly navigate: (location: string) => void;
};

const mapStateToProps = createSelector(
  isSignedInSelector,
  (isSignedIn: boolean) => ({
    isSignedIn
  })
);

const mapDispatchToProps = {
  navigate
};

function QuizLayout({ children, isSignedIn, navigate }: QuizLayoutProps) {
  if (isSignedIn) {
    navigate(`${apiLocation}/signin`);
    return <Loader fullScreen={true} />;
  }

  return <>{children}</>;
}

export default connect(mapStateToProps, mapDispatchToProps)(QuizLayout);
