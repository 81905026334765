export const questions = [
  {
    step: 1,
    question: 'Qual é sua experiência com Programação Front-end e Back-end?',
    options: [
      {
        order: 1,
        value: 'Não tenho experiência.'
      },
      {
        order: 2,
        value: 'Já fiz cursos introdutórios e/ou estudei por conta própria.'
      },
      {
        order: 3,
        value: 'Já fiz alguns cursos e/ou desenvolvi projetos pessoais na área.'
      },
      {
        order: 4,
        value:
          'Já fiz projetos profissionais e/ou tive experiências de trabalho na área.'
      }
    ]
  },
  {
    step: 2,
    question:
      'Qual é sua familiaridade com linguagens e ferramentas de programação?',
    options: [
      {
        order: 1,
        value: 'Não conheço ou tenho pouca familiaridade.'
      },
      {
        order: 2,
        value:
          'Conheço algumas linguagens e ferramentas da área de programação.'
      },
      {
        order: 3,
        value:
          'Uso frequente de várias linguagens e ferramentas da área de programação.'
      },
      {
        order: 4,
        value:
          'Sou proficiente em múltiplas linguagens e ferramentas da área de programação.'
      }
    ]
  },
  {
    step: 3,
    question: 'Qual é sua experiência em projetos relacionados a este tema?',
    options: [
      {
        order: 1,
        value: 'Ainda não participei de nenhum projeto na área.'
      },
      {
        order: 2,
        value: 'Já participei de projetos pequenos ou escolares.'
      },
      {
        order: 3,
        value: 'Já participei de projetos de médio porte ou estágios.'
      },
      {
        order: 4,
        value:
          'Já trabalhei em projetos de grande escala ou em ambiente profissional.'
      }
    ]
  }
];
