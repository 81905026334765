import QuizLayout from '@components/layouts/quiz-layout';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { quizUserSelector } from '@redux/selectors';
import { hardGoTo as navigate, updateQuizUserResult } from '@redux/actions';
import { styled } from '@mui/material';
import AccordionProgressBar from '@ui/Accordion/components/accordion-progress-bar';
import Button from '@ui/Button';
import { ButtonTypes } from '@ui/Button/button-types';
import QuizOptions from '@components/quiz/QuizOptions';
import { Loader } from '@components/helpers';
import { QuizUser } from '../types';
import { questions } from './questions';

type QuizStepsProps = {
  readonly quizUser: QuizUser;
  readonly navigate: (location: string) => void;
  readonly updateQuizUserResult: (payload: {
    userId: string;
    result: number;
  }) => void;
};

type SelectedOptionProps = {
  step: number;
  selectedOption: string | null;
  userResult: number | null;
};

const Container = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const QuizContent = styled('div')`
  max-width: 676px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
`;

const QuestionTitle = styled('h1')`
  color: var(--Pallets-Neutral-700, #484848);
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 28.8px */

  @media (max-width: 960px) {
    font-size: 16px;
    line-height: 150%;
  }
`;

const ButtonArea = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const mapStateToProps = createSelector(
  quizUserSelector,
  (quizUser: QuizUser) => ({
    quizUser
  })
);

const mapDispatchToProps = {
  navigate,
  updateQuizUserResult
};

const initialSelectedOptions = questions.map((_, index) => ({
  step: index + 1,
  selectedOption: null,
  userResult: null
}));

function QuizSteps({
  quizUser,
  navigate,
  updateQuizUserResult
}: QuizStepsProps): JSX.Element {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedOptions, setSelectedOptions] = useState<SelectedOptionProps[]>(
    initialSelectedOptions
  );

  if (!quizUser) {
    navigate('/quiz');
    return <Loader fullScreen={true} />;
  }

  const isLastStep = currentStep === questions.length;
  const isFirstStep = currentStep === 1;
  const currentSelectedOption = selectedOptions[currentStep - 1].selectedOption;

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleChangeOption = (newOption: string, userResult: number) => {
    setSelectedOptions(
      selectedOptions.map((option, index) => {
        if (index === currentStep - 1) {
          return {
            ...option,
            selectedOption: newOption,
            userResult
          };
        }
        return option;
      })
    );
  };

  const handleFinishQuiz = () => {
    const results = selectedOptions.map(option => option.userResult);
    const result1 = results.filter(result => result === 1).length;
    const result2 = results.filter(result => result === 2).length;
    const userResult = result1 > result2 ? 1 : 2;

    updateQuizUserResult({
      userId: (quizUser.id as string) ?? '',
      result: userResult
    });
  };

  return (
    <QuizLayout>
      <Container>
        <QuizContent>
          <AccordionProgressBar
            completedCount={
              currentSelectedOption !== null ? currentStep : currentStep - 1
            }
            numberChallenges={questions.length}
            sentence='type4'
            progressSize='large'
          />

          <QuestionTitle>
            {questions.find(q => q.step === currentStep)?.question}
          </QuestionTitle>

          <QuizOptions
            options={questions.find(q => q.step === currentStep)?.options ?? []}
            onChange={handleChangeOption}
            selectedOption={currentSelectedOption}
          />

          <ButtonArea>
            <Button
              buttonType={ButtonTypes.Tertiary}
              width='133px'
              disabled={isFirstStep}
              onClick={handlePreviousStep}
            >
              VOLTAR
            </Button>

            <Button
              buttonType={ButtonTypes.Primary}
              width='133px'
              disabled={currentSelectedOption === null}
              onClick={isLastStep ? handleFinishQuiz : handleNextStep}
            >
              {isLastStep ? 'FINALIZAR' : 'CONTINUAR'}
            </Button>
          </ButtonArea>
        </QuizContent>
      </Container>
    </QuizLayout>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(QuizSteps);
