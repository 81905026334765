import React, { useEffect } from 'react';
import { styled } from '@mui/material';
import Radio from '@mui/material/Radio';

type OptionProps = {
  order: number;
  value: string;
};

type QuizOptionsProps = {
  readonly options: OptionProps[];
  readonly selectedOption: string | null;
  readonly onChange: (value: string, userResult: number) => void;
};

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`;

const Option = styled('button')<{ isSelected: boolean }>`
  display: flex;
  min-height: 72px;
  padding: 10px 1rem;
  align-items: center;
  border-radius: 8px;
  border: 1px solid
    ${({ isSelected }) =>
      isSelected
        ? 'var(--Pallets-Neutral-700, #484848)'
        : 'var(--text-gray-50, #B7B7B7)'};

  span {
    color: ${({ isSelected }) =>
      isSelected
        ? 'var(--Pallets-Neutral-700, #484848)'
        : 'var(--Pallets-Neutral-400, #858585)'};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
    text-align: left;
  }

  background: transparent;
  cursor: pointer;

  &:hover {
    border: 1px solid var(--Pallets-Neutral-700, #484848);
    background: transparent;
  }
`;

function QuizOptions({
  options,
  selectedOption,
  onChange
}: QuizOptionsProps): JSX.Element {
  const [selectedValue, setSelectedValue] = React.useState<string | null>(null);

  useEffect(() => {
    setSelectedValue(selectedOption);
  }, [selectedOption]);

  const handleChange = (value: string, index: number) => {
    const userResult = index < 2 ? 1 : 2;
    onChange(value, userResult);
    setSelectedValue(value);
  };

  const controlProps = (item: string, index: number) => ({
    checked: selectedValue === item,
    onChange: () => handleChange(item, index),
    value: item,
    inputProps: { 'aria-label': item }
  });

  return (
    <Container>
      {options?.map((option, index) => (
        <Option
          key={option.value}
          isSelected={selectedValue === option.value}
          onClick={() => handleChange(option.value, index)}
        >
          <Radio {...controlProps(option.value, index)} color='default' />
          <span>{option.value}</span>
        </Option>
      ))}
    </Container>
  );
}

export default QuizOptions;
