import React from 'react';
import { useTranslation } from 'react-i18next';

import './accordion-progress-bar.css';

type Props = {
  readonly sentence?: 'type1' | 'type2' | 'type3' | 'type4';
  readonly completedCount: number;
  readonly numberChallenges: number;
  readonly progressSize?: 'small' | 'normal' | 'large';
};

function AccordionProgressBar({
  sentence = 'type1',
  completedCount,
  numberChallenges,
  progressSize = 'normal'
}: Props): JSX.Element {
  const { t } = useTranslation();

  const percentage = (completedCount / numberChallenges) * 100;

  function formatNumber(number: number): string {
    const formattedNumber = number.toFixed(2);
    return formattedNumber.endsWith('.00')
      ? number.toFixed(0)
      : formattedNumber;
  }

  const finalPercentage =
    percentage > 100 ? '100%' : `${formatNumber(percentage)}%`;

  const progressStyle: React.CSSProperties = {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    '--progress': finalPercentage
  };

  const getProgressText = (): string => {
    switch (sentence) {
      case 'type1':
        return t('learn.progress-status', { percentage: finalPercentage });
      case 'type2':
        return t('learn.progress-status-amount', {
          percentage: finalPercentage,
          amount: completedCount,
          total: numberChallenges
        });
      case 'type3':
        return t('learn.progress-status-amount-theme', {
          percentage: finalPercentage,
          amount: completedCount,
          total: numberChallenges
        });
      case 'type4':
        return `${completedCount}/${numberChallenges}`;
      default:
        return t('learn.progress-status', { percentage: finalPercentage });
    }
  };

  const progressText: string = getProgressText();

  return (
    <div
      className={`progress-size-${progressSize} status-progress-container progress-${sentence}`}
    >
      {sentence !== 'type4' && (
        <span className='status-progress'>{progressText}</span>
      )}
      <div className='status-progress-full-bar'>
        <div className='status-progress-amount' style={progressStyle}></div>
      </div>
      {sentence === 'type4' && (
        <span className='status-progress'>{progressText}</span>
      )}
    </div>
  );
}

export default AccordionProgressBar;
